import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref, watch, watchEffect } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { ElLoading } from 'element-plus';
import useMainStore from '@/store';
export default {
  __name: 'App',

  setup(__props) {
    // import HeaderComp from './components/HeaderComp.vue';
    const loader = ref();
    const store = useMainStore();
    const {
      setEventsCategories
    } = store;
    const {
      result: eventsCategoriesRes,
      loading: eventsCategoriesLoading
    } = useQuery(gql`
    query EventsCategoriesQuery {
      eventsCategoriesList {
        items {
          id
          label
          value
        }
      }
    }
  `); // updating local storage

    const {
      result: chosenCityFullRes,
      loading: chosenCityFullLoading
    } = useQuery(gql`
    query ChosenCityFullQuery($value: String!) {
      city(value: $value) {
        id
        country
        value
        yandexCode
      }
    }
  `, {
      value: store.chosenCity?.value
    }, {
      skip: !store.chosenCity
    });
    watch(chosenCityFullRes, chosenCityFullResVal => store.setChosenCity(chosenCityFullResVal.city));
    watch(eventsCategoriesRes, eventsCategoriesResVal => {
      const eventsCategories = eventsCategoriesResVal?.eventsCategoriesList?.items;
      setEventsCategories(eventsCategories.map(eventsCategory => ({ ...eventsCategory,
        isActive: true
      })));
    });
    watchEffect(() => {
      if (eventsCategoriesLoading.value || chosenCityFullLoading.value) {
        loader.value = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      } else {
        loader.value.close();
      }
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createBlock(_component_router_view);
    };
  }

};