import { renderSlot as _renderSlot, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { onMounted, onUpdated, ref } from 'vue';
import { ArrowDownBold } from '@element-plus/icons-vue';
export default {
  __name: 'PopupDrawer',
  props: {
    isOpen: Boolean,
    onClose: Function,
    infoEventPopup: Boolean
  },

  setup(__props) {
    const props = __props;
    const open = ref(props.isOpen);
    onUpdated(() => {
      open.value = props.isOpen;
    });

    const beforeClose = done => {
      props.onClose();
      done();
    };

    const isSmallScreen = ref(window.matchMedia('(max-width: 768px)').matches);
    onMounted(() => {
      window.addEventListener('resize', () => {
        isSmallScreen.value = window.matchMedia('(max-width: 768px)').matches;
      });
    });

    const closeDrawer = () => {
      props.onClose();
      open.value = false;
    };

    return (_ctx, _cache) => {
      const _component_el_icon = _resolveComponent("el-icon");

      const _component_el_drawer = _resolveComponent("el-drawer");

      return _openBlock(), _createBlock(_component_el_drawer, {
        modelValue: open.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => open.value = $event),
        beforeClose: beforeClose,
        direction: isSmallScreen.value ? 'btt' : 'ltr',
        size: "100%",
        modal: false,
        "lock-scroll": false,
        "modal-class": "drawer_container"
      }, {
        default: _withCtx(() => [_renderSlot(_ctx.$slots, "default"), _createVNode(_component_el_icon, {
          onClick: closeDrawer,
          class: _normalizeClass(`arrow-down el-icon--right` + (!__props.infoEventPopup ? '' : ' forEventInfoPopup'))
        }, {
          default: _withCtx(() => [_createVNode(_unref(ArrowDownBold), {
            class: "arrow-down_svg",
            color: "white"
          })]),
          _: 1
        }, 8, ["class"])]),
        _: 3
      }, 8, ["modelValue", "direction"]);
    };
  }

};