import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { COUNTRY_CURRENCY_MAP } from '@/constants';
import useMainStore from '@/store';
import { computed } from 'vue';
export default {
  __name: 'PriceBlock',
  props: {
    price: String
  },

  setup(__props) {
    const props = __props;
    const store = useMainStore();
    const isPriceNaN = computed(() => isNaN(parseFloat(props.price)));
    const priceText = computed(() => {
      if (!props.price) return 'Free';
      const price = isPriceNaN.value ? props.price : parseFloat(props.price);
      return (!isPriceNaN.value && COUNTRY_CURRENCY_MAP[store.chosenCity.country] || '') + price;
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("span", {
        class: _normalizeClass(props.price && !_unref(isPriceNaN) ? '' : 'any-text')
      }, _toDisplayString(_unref(priceText)), 3);
    };
  }

};