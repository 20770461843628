import { unref as _unref, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-0bb73f0c"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "buttons_wrap"
};
const _hoisted_2 = ["href"];
const _hoisted_3 = ["src"];
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import useMainStore from '@/store';
import PopupDrawer from '@/ui/PopupDrawer.vue';
import mapLocationPath from '@/assets/map-location.svg';
import { CITY_RU_TO_ENG } from '@/constants';
export default {
  __name: 'EventInfoPopup',

  setup(__props) {
    const store = useMainStore();
    const {
      setEventInfoPopupVisibility
    } = store;
    const {
      chosenCity,
      chosenEvent,
      eventInfoPopupVisibility
    } = storeToRefs(store);

    const onEventInfoPopupClose = () => {
      setEventInfoPopupVisibility(false);
    };

    const chosenEventLongLat = computed(() => chosenEvent.value.place ? chosenEvent.value.place.coordinates.coordinates : chosenEvent.value.coordinates.coordinates);
    const hrefToMap = computed(() => `https://yandex.com.ge/maps/${chosenCity.value.yandexCode}/${CITY_RU_TO_ENG[chosenCity.value.value]?.toLowerCase()}/?ll=${chosenEventLongLat.value[0]}%2C${chosenEventLongLat.value[1]}&mode=whatshere&whatshere%5Bpoint%5D=${chosenEventLongLat.value[0]}%2C${chosenEventLongLat.value[1]}&whatshere%5Bzoom%5D=${chosenEvent.value.isAddressAccurate ? 17 : 12}&z=${chosenEvent.value.isAddressAccurate ? 15 : 13}`);
    return (_ctx, _cache) => {
      const _component_CardComp = _resolveComponent("CardComp");

      const _component_el_button = _resolveComponent("el-button");

      return _openBlock(), _createBlock(PopupDrawer, {
        isOpen: _unref(eventInfoPopupVisibility),
        onClose: onEventInfoPopupClose,
        infoEventPopup: true
      }, {
        default: _withCtx(() => [_createVNode(_component_CardComp, _mergeProps(_unref(chosenEvent), {
          isBriefVersion: false,
          cardShadow: "never"
        }), null, 16), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_button, {
          type: "primary"
        }, {
          default: _withCtx(() => [_createElementVNode("a", {
            target: "_blank",
            href: _unref(hrefToMap)
          }, [_createElementVNode("img", {
            src: _unref(mapLocationPath),
            class: "btn_icon"
          }, null, 8, _hoisted_3)], 8, _hoisted_2)]),
          _: 1
        })])]),
        _: 1
      }, 8, ["isOpen"]);
    };
  }

};