import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-60ed5fc0"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "map_container"
};
import BaseMap from '@/components/BaseMap.vue';
import EventsPopup from '@/components/EventsPopup/EventsPopup.vue';
import EventInfoPopup from '@/components/EventInfo/EventInfoPopup.vue';
export default {
  __name: 'MapView',

  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(BaseMap), _createVNode(EventsPopup), _createVNode(EventInfoPopup)]);
    };
  }

};